var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(!_vm.loading)?_c('div',{staticClass:"row form__body--card form__common--center"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-1"}),_c('div',{staticClass:"col-sm-6"},[_c('form',{staticClass:"needs-validation",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom01"}},[_vm._v(_vm._s(_vm.$t('pagePartner.name'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticStyle:{"position":"absolute","left":"100%","top":"5px"}},[_c('company',{on:{"data":_vm.handleData}})],1),_c('InputText',{attrs:{"id":"validationCustom01","model":_vm.form.name,"config":{
                                                isRequired: !_vm.$v.form.name.required,
                                                isMaxLength: !_vm.$v.form.name.maxLength,
                                                error: _vm.submitted && _vm.$v.form.name.$error,
                                                errorField: _vm.$t('pagePartner.name')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "name", $event)}}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom02"}},[_vm._v(_vm._s(_vm.$t('pagePartner.name_kana'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"validationCustom02","model":_vm.form.kana_name,"config":{
                                                isRequired: !_vm.$v.form.kana_name.required,
                                                isMaxLength: !_vm.$v.form.kana_name.maxLength,
                                                isKatakana: !_vm.$v.form.kana_name.katakana,
                                                error: _vm.submitted && _vm.$v.form.kana_name.$error,
                                                errorField: _vm.$t('pagePartner.name_kana')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "kana_name", $event)}}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v("法人番号 ")]),_c('div',{staticClass:"col-sm-6"},[_c('InputText',{attrs:{"id":"validationCustom05","model":_vm.form.corp_number,"config":{
                                                error: false
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "corp_number", $event)},"change":_vm.inputCorporate}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom03"}},[_vm._v(_vm._s(_vm.$t('pagePartner.phone')))]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"validationCustom03","model":_vm.form.phone_number,"config":{
                                                isMaxLength: !_vm.$v.form.phone_number.maxLength,
                                                isNumber: true,
                                                maxlength: 20,
                                                error: _vm.submitted && _vm.$v.form.phone_number.$error,
                                                errorField: _vm.$t('pagePartner.phone')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "phone_number", $event)}}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom04"}},[_vm._v(_vm._s(_vm.$t('pagePartner.postalCode'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"validationCustom04","model":_vm.form.postal_code,"config":{
                                                isRequired: !_vm.$v.form.postal_code.required,
                                                isMaxLength: !_vm.$v.form.postal_code.maxLength,
                                                maxlength: 20,
                                                isNumber: true,
                                                error: _vm.submitted && _vm.$v.form.postal_code.$error,
                                                errorField: _vm.$t('pagePartner.postalCode')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "postal_code", $event)},"input":_vm.inputCode}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('pagePartner.province'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"validationCustom05","model":_vm.form.province,"config":{
                                                isRequired: !_vm.$v.form.province.required,
                                                isMaxLength: !_vm.$v.form.province.maxLength,
                                                maxlength: 255,
                                                error: _vm.submitted && _vm.$v.form.province.$error,
                                                errorField: _vm.$t('pagePartner.province')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "province", $event)},"input":_vm.inputAddress}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom06"}},[_vm._v(_vm._s(_vm.$t('pagePartner.address_1'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"validationCustom06","model":_vm.form.address_1,"config":{
                                                placeholder: _vm.$t('pldCommon.address_1'),
                                                isRequired: !_vm.$v.form.address_1.required,
                                                isMaxLength: !_vm.$v.form.address_1.maxLength,
                                                maxlength: 255,
                                                error: _vm.submitted && _vm.$v.form.address_1.$error,
                                                errorField: _vm.$t('pagePartner.address_1')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "address_1", $event)},"input":_vm.inputAddress}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom07"}},[_vm._v(_vm._s(_vm.$t('pagePartner.address_2')))]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"validationCustom07","model":_vm.form.address_2,"config":{
                                                placeholder: _vm.$t('pldCommon.address_2'),
                                                isMaxLength: !_vm.$v.form.address_2.maxLength,
                                                maxlength: 255,
                                                error: _vm.submitted && _vm.$v.form.address_2.$error,
                                                errorField: _vm.$t('pagePartner.address_2')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "address_2", $event)}}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom08"}},[_vm._v(_vm._s(_vm.$t('pagePartner.description')))]),_c('div',{staticClass:"col-sm-12"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.note),expression:"form.note"}],staticClass:"form-control",attrs:{"id":"validationCustom08"},domProps:{"value":(_vm.form.note)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "note", $event.target.value)}}})])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom09"}},[_vm._v(_vm._s(_vm.$t('pagePartner.url')))]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"validationCustom05","model":_vm.form.url,"config":{
                                                isUrl: !_vm.isUrl,
                                                maxlength: 255,
                                                error: _vm.submitted && !_vm.isUrl,
                                                errorField: _vm.$t('pagePartner.url')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "url", $event)},"change":_vm.inputUrl}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom10"}},[_vm._v(_vm._s(_vm.$t('pagePartner.email'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"validationCustom10","model":_vm.form.email,"config":{
                                                isRequired: !_vm.$v.form.email.required,
                                                isMaxLength: !_vm.$v.form.email.maxLength,
                                                isEmail: !_vm.$v.form.email.email,
                                                maxlength: 255,
                                                error: _vm.activeHasEmail && _vm.$v.form.email.$error,
                                                errorField: _vm.$t('pagePartner.email')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "email", $event)},"change":() => {
                                                    _vm.activeHasEmail = false;
                                                }}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom11"}},[_vm._v(_vm._s(_vm.$t('pagePartner.type'))+" ")]),_c('div',{staticClass:"col-sm-12"},[_c('button',{staticClass:"btn btn-sm btn-info",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleOpenScopeOrder()}}},[_vm._v("詳細")])])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"mailboxAddress"}},[_vm._v(_vm._s(_vm.$t('pagePartner.mailboxAddress')))]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"mailboxAddress","model":_vm.form.mailbox_address,"config":_vm.configMailbox},on:{"update:model":function($event){return _vm.$set(_vm.form, "mailbox_address", $event)},"update:config":function($event){_vm.configMailbox=$event}}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"directAddress"}},[_vm._v(_vm._s(_vm.$t('pagePartner.directAddress')))]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"directAddress","model":_vm.form.direct_address,"config":_vm.configDirect},on:{"update:model":function($event){return _vm.$set(_vm.form, "direct_address", $event)},"update:config":function($event){_vm.configDirect=$event}}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom12"}},[_vm._v(_vm._s(_vm.$t('pagePartner.tool')))]),_c('div',{staticClass:"col-sm-12"},_vm._l((_vm.form.tools),function(itemTool,idxTool){return _c('div',{key:`${idxTool}_toolss`},[_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('InputText',{staticClass:"flex-fill",attrs:{"id":`validationTool_${idxTool}`,"model":itemTool.tool,"config":{
                                                        isMaxLength: !_vm.$v.form.tools.$each[idxTool].tool.maxLength,
                                                        error: _vm.submitted && _vm.$v.form.tools.$each[idxTool].tool.$error,
                                                        errorField: _vm.$t('pagePartner.tool')
                                                    }},on:{"update:model":function($event){return _vm.$set(itemTool, "tool", $event)}}}),_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"10px"}},[_c('button',{staticClass:"btn btn-sm btn-outline-info",staticStyle:{"border-radius":"50%"},attrs:{"type":"button"},on:{"click":function($event){return _vm.addTools()}}},[_c('i',{staticClass:"fa fa-plus"})]),_c('button',{staticClass:"btn btn-sm btn-outline-danger",staticStyle:{"border-radius":"50%"},attrs:{"type":"button","disabled":_vm.form.tools.length === 1},on:{"click":function($event){return _vm.deleteTool(idxTool)}}},[_c('i',{staticClass:"fa fa-minus"})])])])],1)])}),0)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom12"}},[_vm._v(_vm._s(_vm.$t('pagePartner.searchKeyword')))]),_c('div',{staticClass:"col-sm-12"},[_c('KeyWordTag',{attrs:{"config":_vm.configKeyword}}),(_vm.configKeyword.error)?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('pagePartner.searchKeyword') }))+" ")]):_vm._e()],1)])])])])])])]),_c('Footer',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-center flex-fill"},[_c('button',{staticClass:"btn btn-light mr-3 btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({
                                path: '/partners'
                            })}}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")]),(_vm.checkPermiss('partner.register') || (_vm.checkPermiss('partner.edit') && _vm.$route.query.id))?_c('button',{staticClass:"btn btn-primary ml-3",attrs:{"type":"submit"},on:{"click":_vm.formSubmit}},[_vm._v(" "+_vm._s(_vm.$route.query.id ? _vm.$t('btn.save') : _vm.$t('btn.register'))+" ")]):_vm._e()])])])],1):_c('div',{staticClass:"d-flex justify-content-center"},[_c('LoadingIcon')],1),_c('ModalCommon',{attrs:{"id":"ModalOrder","config":_vm.configModalCommon},on:{"save":function($event){return _vm.handleSaveScopeOrder()},"close":function($event){return _vm.handleCloseScopeOrder()}}},[_c('form',{staticClass:"needs-validation",attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v("受注範囲")]),_c('div',{staticClass:"col-sm-9"},[_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.listMasterOrderScope),function(orderLv1){return _c('div',{key:orderLv1.id,staticClass:"custom-control custom-checkbox mr-4 py-1",staticStyle:{"min-width":"100px"}},[_c('InputCheckBox',{attrs:{"model":_vm.listChecked.msOrderLv1,"config":{
                                    id: `check_app${orderLv1.id}`,
                                    value: orderLv1.id,
                                    label: orderLv1.value
                                }},on:{"update:model":function($event){return _vm.$set(_vm.listChecked, "msOrderLv1", $event)},"change":function($event){return _vm.handleChangeOrderLv1(orderLv1)}}})],1)}),0)])]),(_vm.listChecked.msOrderLv1.length)?_c('div',_vm._l((_vm.listOrderFilterLv1),function(itemFilterLv1){return _c('div',{key:`${itemFilterLv1.id}-filter1`,staticClass:"py-3 mt-3",staticStyle:{"box-shadow":"rgba(0, 0, 0, 0.2) 0px 5px 15px","border-radius":"10px"}},[_c('div',[_c('div',{staticClass:"ml-1 my-y"},[_c('label',{staticClass:"custom-control px-3"},[_vm._v(_vm._s(itemFilterLv1.value))])]),_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-sm-1"}),_c('div',{staticClass:"col-sm-11 row"},_vm._l((itemFilterLv1.data),function(dataFilterLv1,idxF1){return _c('div',{key:`${idxF1}-medium_item_filter1`},[_c('InputCheckBox',{staticClass:"mr-4 py-1",staticStyle:{"min-width":"100px"},attrs:{"model":_vm.listChecked.msOrderLv2,"config":{
                                            id: `check_app${dataFilterLv1.id}`,
                                            value: dataFilterLv1.id,
                                            label: dataFilterLv1.value
                                        }},on:{"update:model":function($event){return _vm.$set(_vm.listChecked, "msOrderLv2", $event)},"change":function($event){return _vm.handleChangeOrderLv2(dataFilterLv1)}}})],1)}),0)])])])}),0):_vm._e()])])],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }